
import common from '@/mixin/common.js'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'r-scene-detail',
  mixins: [common],
  data() {
    return {
      bgImage: require('@/assets/scene/bg_detail.png'),
      selectText: null,
      currentTypeIndex: 0,
      currentActiveLink: '',
      anchorAtBottom: false,
    }
  },
  computed: {
    ...mapGetters('scene', ['sceneList']),
    ...mapGetters('scene', ['articleList']),
  },
  // created() {
  //   window.onscroll = this.scrollEvent;
  // },
  mounted() {
    const key = this.$route.hash;
      // const index = parseInt(this.$route.params.id);
    this.changeBreadInfo(key);
    // setTimeout(()=>{
    //   if (index !== 0) {
    //     this.changeType(index);
    //   }
    // }, 300);
    // if (index !== 0) {
    //   this.changeType(index);
    // }
    // this.$nextTick(()=>{
    //   // this.$router.push('#' + key);
    //   location.href = '#' + key;
    // });
    window.addEventListener('scroll',this.scrollEvent,true);
    this.getArticleList();
  },
  methods: {
    ...mapActions('scene', ['getArticleList']),
    changeType(index) {
      this.changeSelectType(index);
      this.$nextTick(()=>{
        let jump = document.querySelectorAll('.scenes');
        jump[index].scrollIntoView({ block: 'start', behavior: 'smooth' });
      });
    },
    scrollEvent(e) {
      this.$nextTick(()=>{
        try {
          const content = this.$refs.product_content;
          const bodyOffset = document.body.scrollTop || document.documentElement.scrollTop;
          // console.log(bodyOffset, content.offsetTop, content.offsetHeight, content.scrollHeight);
          // const offset = this.$refs.product_content.offsetTop - (document.body.clientHeight || document.documentElement.clientHeight);
          this.anchorAtBottom = (bodyOffset >= content.scrollHeight - 104);
        } catch (error) {
        }
      })
    },
    // scrollEvent(e) {
    //   let scrollItems = document.querySelectorAll('.scenes');
    //   for (let i = scrollItems.length - 1; i >= 0; i--) {
    //     // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
    //     let judge = scrollItems[i].getBoundingClientRect().top <= 20;
    //     console.log(judge);
    //     if (judge) {
    //       this.changeSelectType(i);
    //       break;
    //     }
    //   }
    // },
    changeSelectType(params) {
      this.$nextTick(()=>{
        this.currentTypeIndex = params;
        this.changeBreadInfo(params);
      })
    },
    changeBreadInfo(key) {
      this.currentActiveLink = key.replace('#', '');
      const scene = this.sceneList.find(u => u.key === this.currentActiveLink);
      const name = this.$t('scenedetail.type' + scene.id);
      if (!name) return;
      this.selectText = name;
      if (!this.$refs.breadcrumb) return; 
      this.$refs.breadcrumb.changeLastBread(name);
    },
    selectTap(params = 0) {
      this.changeType(params);
      this.changeBreadInfo(params);
    },
    showDetail(params) {
      if (!params || !params.id);
      this.$router.push('/news/detail/' + params.id); 
    },

    anchorChange(currentActiveLink) {
      this.currentActiveLink = currentActiveLink.replace('/#/g', '');
      this.changeBreadInfo(this.currentActiveLink);
    },
  },
}